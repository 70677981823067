.form_group_class {
	margin: 0 15px 20px;
	padding: 0;
	border-style: none;
	background-color: white;
	will-change: opacity, transform;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 black;
	border-radius: 4px;
}

.form_row_class {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin-left: 15px;
	border-top: 1px solid #819efc;
}

.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	width: 100%;
	padding: 11px 15px 11px 0;
}

.button_custom_class {
	display: block;
	font-size: 16px;
	width: calc(100% - 30px);
	height: 40px;
	margin: 40px 15px 0;
	background-color: red;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #ffb9f6;
	border-radius: 4px;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
	transition: all 100ms ease-in-out;
	will-change: transform, background-color, box-shadow;
	border: none;
}

.button_custom_class:active {
	background-color: red;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #e298d8;
	transform: scale(0.99);
}


.payment-success{
	color: green;
	text-align: center;
}
.Thank-you{
	color: black;
  text-align: center;
}
.error{
	color: red
}



/* new car section */
body {
  margin: 0;
  padding: 0;
}

.App {
  font-family: sans-serif;
  height: 100vh;
  display: flex;
  align-items: center;
}

.StripeElement {
  margin: 15px auto;
  padding: 10px 12px;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.product {
  width: 100%;
  max-width: 450px;
  margin: auto;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
}

.btn-pay {
  display: block;
  width: 100%;
  border: none;
  background: linear-gradient(
    135deg,
    rgb(49, 0, 62) 0%,
    rgb(195, 40, 110) 100%
  );
  color: #fff;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}

.product-info {
  padding: 0 16px;
}

h3.product-title {
  font-size: 28px;
  margin-bottom: 15px;
}

h4.product-price {
  font-size: 24px;
  margin: 0;
  margin-bottom: 30px;
  color: #777;
  font-weight: 500;
}
