:root {
  --dynamic-gradient: linear-gradient(236.28deg, #CA1744 4.07%, #0B1449 75.27%);
  --dashboard-gradient: linear-gradient(236.28deg, #CA1744 0%, #0B1449 75.27%);
  --banner-background-image: ""; 
  --banner-background-color: transparent;
  --side-menu-background-color: #0A0F2E;
}

  
body{font-family: 'Poppins', sans-serif; color: #000000;}
ul{margin: 0; padding: 0; list-style: none;}
figure{margin:0;}
img{max-width: 100%; height: auto;}
a {display: inline-block;-moz-transition:all 0.5s ease 0s;-ms-transition:all 0.5s ease 0s;-o-transition:all 0.5s ease 0s;-webkit-transition:all 0.5s ease 0s;transition:all 0.5s ease 0s;text-decoration: none;}

.btn-primary{background-color:#0A0F2E; border-color:#0A0F2E;  color: #ffffff; font-size: 18px; padding: 10px 20px; border-radius: 6px;}
.btn-primary:active, .btn-primary:focus, .btn-primary:hover{background-color:#ffffff; border-color:#0A0F2E; color: #0A0F2E; box-shadow: none;}
button:focus:not(:focus-visible){outline: 0; box-shadow: none;}

header{background-color: #0A0F2E; position: relative; z-index: 9;}
header:after{position:absolute; content: ""; left: 0; right: 0; bottom: 0; width: 100%; height: 3px; margin: auto; background:  linear-gradient(90deg, rgba(202, 23, 68, 0) 0%, #CA1744 49.71%, rgba(202, 23, 68, 0) 100%);}
.header{padding: 10px 0; display: flex; align-items: center; justify-content: space-between;}
.logo img{max-width: 200px;}
.header .navbar{padding: 0;}
.header .navbar-expand-lg .navbar-nav{align-items: center;}
.header .navbar-expand-lg .nav-item{margin-right: 30px;}
.header .navbar-expand-lg .nav-item .gameimg img{max-width: 26px;}
.header .navbar-expand-lg .nav-item:last-child{margin-right: 0;}
.header .navbar-expand-lg .navbar-nav .nav-link{color: #ffffff; padding: 0; font-size: 16px;}
.header .navbar-expand-lg .navbar-nav .nav-link img{margin: 0 10px 0 0; max-width: 15px;}
.header .navbar-expand-lg .navbar-nav .nav-link:hover{color: #ffffff;}

.header .navbar-collapse .dropdown-menu[data-bs-popper] {right: 0; left: auto; padding: 0;}
.header .navbar-collapse .dropdown-menu:after{width: 0; height: 0; border-left: 8px solid transparent; border-right: 8px solid transparent; border-bottom: 8px solid #ffffff; 
position: absolute; top: -8px; right: 45px; content: '';}
.header .navbar-collapse .dropdown-menu .dropdown-item{padding: 8px 20px;}
.header .dropdown-toggle, .header .dropdown .dropdown-toggle{position: relative; display: flex; align-items: center; padding-right: 30px; background-color: transparent;
    border: none;     color: #fff} 
.header .dropdown-toggle figure{display: inline-block; height: 48px; width: 48px; border-radius: 50%; overflow: hidden; position: relative;}
.header .dropdown-toggle::after{position: absolute; right: 0; content: ""; bottom: 0; top: 0; margin: auto; border: none; height: 8px; width: 14px; background: url("../images/dropdown-arrow.png") no-repeat; background-position: right center; }
.container-fluid{padding-left: 30px; padding-right: 30px; position: relative; z-index: 1;}


/* .banner-section{background-image: url("../images/bannerbg1.jpg"); background-repeat: no-repeat; background-size: cover; position: relative; z-index: 2;} */

.banner-section {
  background-image: var(--banner-background-image);
  background-color: var(--banner-background-color);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 2;
}



.banner-content{display: flex; justify-content: space-between; align-items: center; padding: 0 30px; min-height: 275px; position: relative;}
/*.banner-content:after{position: absolute; content: ""; left: 0; top: 0; height: 100%; width: 100%; background-color:rgb(0 0 0 / 0.7);}*/
.left-section{display: flex; width: 70%; align-items: center; z-index: 1;}
.left-section > figure{height: 195px; width: 195px; border-radius: 13px; background-color: #ffffff; display: flex; align-items: center; justify-content: center; flex-wrap: wrap;}
.gamebar_detail .game-btn{display: flex;}
.game-btn{align-items: center; display: none;}
.right-section{display: flex; align-items: center; justify-content: flex-end; position: relative; z-index: 1;}
.banner-ads, .brand-logo{display: flex; align-items: center; justify-content: center; flex-wrap: wrap; height: 208px; width: 208px; margin-right: 20px;}

.gamebar_detail{padding-left: 40px;}
.custom-select {border: none; background-color: transparent; color: #ffffff; font-weight: 600; font-size: 30px; white-space: nowrap; max-width: 570px; text-overflow: ellipsis; margin-bottom: 15px;}
.custom-select option{font-size: 16px; color: #000000;}
.game-btn li{margin-right: 10px;}
.game-btn li:last-child{margin-right: 0;}
.game-btn li a{background: #FFD43C; border: 1px solid #FFD43C; border-radius: 6px; color: #000000; font-style: normal; font-weight: 600; font-size: 16px; line-height: 27px; padding: 9px 25px; height: 48px; display: flex; align-items: center;}
.game-btn li a img{margin: 0 10px 0 0; max-width: 15px; filter: -webkit-brightness(0); filter: brightness(0);}
.game-btn li a:hover{background: #ffffff; border: 1px solid #ffffff; color: #000000;}

.game_navbar{display: flex; align-items: center; justify-content: space-between; position: relative;}
.btninfo-block{padding: 0 0 0 30px; display: flex; margin: 0 -5px;}
.btninfo-block a{background: #ffffff; border: 1px solid #0B1449; border-radius: 6px; color: #0A0F2E; font-style: normal; font-size: 16px; line-height: 27px; display: inline-block; padding: 9px 20px; height: 48px; margin: 0 5px 5px;}
.btninfo-block a img{margin: 0 10px 0 0;}
.btninfo-block a img.lockimg{margin: 0 0 0 10px;}
.btninfo-block a.lock{color: #9da1b6;}

.gamebar-rightinfo {display: flex; align-items: center;}
.game-state{display: flex; align-items: center; padding: 0 15px;}
.game-state li{text-align: center; padding: 0 15px; position: relative;}
.game-state li:after{position: absolute; content: ""; right: 0; top: 0; bottom: 0; margin: auto; height: 100%; width: 1px; background-color:rgba(0,0,0,0.2);}	
.game-state li:nth-child(4):after, .game-state li:last-child:after{display: none;}	
.game-state li .gamepassswitch{display: none;}
.game-state li span{width: 100%; display: block; font-size: 13px; line-height: 21px; color: #444444;}
.game-state li strong{width: 100%; display: block; font-weight: 700; font-size: 16px; line-height: 27px; text-align: center; color: #000000;}

.counter{background-color: #FFD43C; padding:0 30px;}
.counter p {font-size: 15px; line-height: 22px; text-align: center; color: #000000; margin: 0 0 5px;}
.counter ul{display: flex; align-items: center; margin: 0 -10px; justify-content: center;}
.counter ul li{position: relative; text-align: center; padding: 0 10px;}
.counter ul li:after{position: absolute; content: ":"; top: 8px; right: 0;}
.counter ul li:last-child:after{display: none;}
.counter ul li span{width: 100%; display: block; font-size: 13px; line-height: 20px; text-align: center; color: #000000;}
.counter ul li strong{display: flex; align-items: center; justify-content: center; width: 42px; height: 42px; background: #000000; border-radius: 5px; font-size: 18px; line-height: 30px; text-align: center; color: #FFFFFF;}



/* .middle-section{background: linear-gradient(236.28deg, #CA1744 4.07%, #0B1449 75.27%); position: relative;} */
.middle-section {
    background: var(--dynamic-gradient);
    position: relative;
  }

  
.middle-section:after{position: absolute; content: ""; left: 0; top: 0; width: 100%; height: 100%; background: url("../images/texturebg.png") no-repeat;}
.matchdata{display: flex; justify-content: space-between; flex-direction: row-reverse; align-items: flex-start; padding: 30px 20px 80px; position: relative; z-index: 1; max-width: 1920px; margin: auto;}
.tabledata{width: 45%;}
.pitchsection{width: 50%; position: relative;}


.table tr td{background-color: #f4f3f6; text-align: center; vertical-align: middle;}
.table tr td .title{font-size: 14px; line-height: 21px; text-align: center; color: #444444; display: block;}
.table tr td > strong{display: block; font-size: 14px;}
.playerinfo{position: relative; padding: 0 0px 0 50px; display: flex; align-items: center;}
.playerinfo > a{display:flex; color: #000000; align-items: center;}
.bonuscard{height: 20px; width: 20px; border-radius: 50%; overflow: hidden; margin: 0 0 0 10px; display: flex; align-items: center; justify-content: center;}
.available{position: relative; padding: 0px 0 0 18px; text-align: left; display: flex; align-items: center; font-size:12px;}
.available:after{position: absolute; content: ""; left: -12px; top: 10px; display: block; width: 7px; height: 7px; background: #46AB21; border-radius: 50%;}
.unavailable{position: relative; padding: 0px 0 0 18px; text-align: left; display: flex; align-items: center; font-size:12px;}
.unavailable:after{position: absolute; content: ""; left: -12px; top: 10px; display: block; width: 7px; height: 7px; background: #FF372B; border-radius: 50%;}
.playerimg{position: absolute; left: 0; top: 0; bottom: 0; height: 40px; margin: auto;}
.playerimg figure{background-color: #165357; width: 40px; height: 40px; border-radius: 50%; display: flex; align-items: flex-end; overflow: hidden; margin: auto;}
.playerimg img{max-height: 48px;}
.playerimg img.verified{max-width: 16px; position: absolute; right: 0px; bottom: 0;}
.playername{margin: 0;  font-size: 15px; line-height: 26px; color: #000000; position: relative; text-align: left; padding: 0px 0px 0px 14px;}
.clubname{margin: 0px; font-size: 12px; text-align: left; line-height: 22px;}
.playername .teamcircle{display: inline-block; width: 20px; height: 20px; border-radius: 50%; background: #FFD43B;  font-size: 14px; line-height: 21px; color: #000000; text-align: center; margin-left: 5px;}
.infotoggle{display: inline-block; margin:-1px 0 0 5px;}
.infotoggle img{max-width: 16px;}

.tabledata .nav-tabs{border-bottom:none; background-color: #ffffff; justify-content: center;}
.tabledata .nav-tabs .nav-item{flex: 1 1 auto; z-index: 1;}
.tabledata .nav-tabs .nav-link{width: 100%; border: none; font-size: 15px; line-height: 18px; text-align: center; color: #000000; padding: 12px 16px;}
.tabledata .nav-tabs .nav-link:hover, .tabledata .nav-tabs .nav-link.active{border: none; position: relative;}
.tabledata .nav-tabs .nav-link:hover:after, .tabledata .nav-tabs .nav-link.active:after{position: absolute; content: ""; bottom: 0; left: 0; right: 0; margin: auto; width: 44px; height: 3px; background: #0B1449; border-radius: 2.5px 2.5px 0px 0px;}
.tabledata .tab-content{background-color: #ffffff; border-radius:4px 4px 0 0; overflow: hidden;}
.tabledata .tab-content .mytable{margin: 0;}
.tabledata .tab-content .mytable tr:last-child td{border: none;}
.matchdata .tabledata .tab-content .customscroll {margin: 0;overflow-y: auto;max-height: 760px;}
.addplayer img{max-height: 36px;}

.searchfilter{background-color: #ffffff; padding: 15px 95px 15px 15px; position: relative; border-radius: 12px 12px 0 0; min-height: 55px;}
.searchfilter .form-control{background: #EEF0F8; border-radius: 8px; border: none; height: 65px; padding: 12px 12px 12px 70px; font-size: 14px;}
.searchfilter .form-control:focus{border: none; box-shadow: none;}
.searchfilter .searchimg{position: absolute; left: 35px; top: 34px;}
.searchfilter .dropdown{position: absolute; right: 22px; top: 33px;}
.searchfilter .dropdown .dropdown-toggle:after{display: none;}
.searchfilter .dropdown .dropdown-menu{right: 0 !important; left: auto !important; padding: 0;}
.searchfilter .dropdown .dropdown-menu li a{padding: 10px 15px;}

.strip-ads{margin: 42px 0 0; text-align: center;}

.additional-option{padding-right: 25px; margin: 0 0 20px; position: relative; z-index: 2; display: flex; justify-content:space-between; align-items: center;}
.additional-list{display: flex; margin: 0 0 0 auto;}
.additional-list > li{padding: 0 5px;}
.additional-list li > .dropdown > a.dropdown-toggle{display: flex; flex-wrap: wrap; justify-content: center; align-items: center; width: 36px; height: 36px; border-radius: 50%; background: #FFFFFF; padding: 5px;}
.additional-list li > .dropdown > a.dropdown-toggle:after{display: none;}

.additional-list .dropdown-menu.show{display: block; transform: inherit !important; inset: inherit !important; min-width: 275px; right: 0 !important; left: auto !important; top: 45px !important}
.additional-list .dropdown-toggle.show{position: relative;}
.additional-list .dropdown-toggle.show:before{position: absolute; content: ""; bottom: -7px; right: 5px; height: 12px; width: 24px; background: url("../images/bottom-arrow.png") no-repeat;}
.additional-list .dropdown .dropdown-menu{padding: 0;}
.additional-list .dropdown .keyoption li a{padding: 15px 10px; font-size: 14px;}
.additional-list .dropdown .notificationoption li a{display: block; padding: 8px 10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 275px; color: #000000; font-size: 14px; }
.additional-list .dropdown .socialicon ul{display: flex; justify-content: center; align-items: center; margin: 0 -8px; padding:15px 0;}
.additional-list .dropdown .socialicon li{padding: 0 8px;}
.additional-list .dropdown .socialicon li a{display: flex; justify-content: center; align-items: center; width: 36px; height: 36px; border-radius: 50%; border: 1px solid #0B1449; background: #0B1449; color: #ffffff;}
.additional-list .dropdown .socialicon li a:hover{background: #BC1742; border: 1px solid #BC1742;}

.additional-list .dropdown .tradeoption{padding: 15px 12px;}
.additional-list .dropdown .tradeoption li{padding: 8px 0;}
.additional-list .dropdown .tradeoption li a{display: flex; align-items: center; background: #0B1449; box-shadow: 0px 4px 0px #BC1742; border-radius: 12px; font-size: 14px; line-height: 45px; height: 45px; text-transform: uppercase; color: #FFFFFF; padding: 0 10px;}
.additional-list .dropdown .tradeoption li a img{margin: 0 12px 0 0;}
.additional-list .dropdown .tradeoption li a:hover{background-color: #000000;}

.additional-list .border-btn {height: auto; border: none; padding: 8px 10px; font-size: 14px; line-height: 20px;}



.sponsorlogo {display: flex; align-items: center; justify-content: space-between; position: absolute; left: 0; right: 0; max-width: 550px; margin: auto; top: 30px;}
.small-logo{height: 52px; width: 52px; border: 2px solid #FFFFFF; background: #FFFFFF; overflow: hidden; border-radius: 3px; display: flex; align-items: center; justify-content: center;}
.big-logo img{max-height: 32px;}
.team-fieldview {position: absolute; top: 120px; left: 0; right: 0; margin: auto; display: flex; flex-direction: column; align-content: space-between; justify-content: space-between; bottom: 180px;}
.team-fieldview > ul{display: flex; justify-content: center; margin: 0 -18px 0;}
.team-fieldview > ul:last-child{margin-bottom:0px;}
.team-fieldview > ul > li{padding: 0 7px; position: relative;}
.team-fieldview > ul > li > figure {height: 70px; width: 70px; margin: auto; background-color: #0A0F2E; border-radius:4px; overflow: hidden; border: 1px solid #767676; display: flex; align-items: flex-end; flex-wrap: wrap; justify-content: center;}
.team-fieldview > ul > li > figure img{max-width: 60px;}
.addplayerlink{color: #ffffff; display: block; text-align: center; position: absolute; left: 0; right: 0; top: 15px; max-width: 50px; margin: auto;}
.addplayerlink:hover{color: #ffffff; text-decoration: none;}
.addplayerlink span{display: block; text-transform: capitalize; font-size: 10px; line-height: 13px;}

.pitchplayer{display: block; text-align: center; position: absolute; left: 0; right: 0; bottom:auto; max-width: 50px; margin:-2px auto 0;}
.pitchplayer figure img{max-width: 50px;}
.pitchplayer h5{font-size: 12px; background-color: #ffffff; padding:4px 3px 4px 12px; text-align:left; margin: 0 -15px 3px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}
.pitchplayer h5 .available::after, .pitchplayer h5 .unavailable::after{left: -8px; top: 5px; width: 6px; height: 6px;}

.pitchplayer span{display: block; font-size: 12px; color: #ffffff;}
.pitchplayer span.pitch_player_position{color: #000000; margin-left: -12px; text-align: center;}
.more-option{position: absolute; top: -7px; right: 2px; z-index: 1;}
.more-option .optionbtn{width:18px; height:18px; background: #FFD43B; border-radius: 50%; display: flex; flex-wrap: wrap; justify-content: center; align-items: center;}
.more-option .optionbtn img{padding: 0 0 0 1px;}
.more-option .optionbtn .closeimg{display: none; padding-top: 1px;}
.more-option ul{position: absolute; top: -26px; right: -22px; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s;  -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s; -webkit-transform:scale(0); -moz-transform:scale(0); -o-transform:scale(0); -ms-transform:scale(0); transform:scale(0);}
.more-option ul li{display: block; padding: 2px 0;}
.more-option ul li a{width:20px; height:20px; background: #ffffff; border-radius: 50%; display: flex; flex-wrap: wrap; justify-content: center; align-items: center; font-size: 10px; color: #000000; text-decoration: none;}
.more-option.show .optionbtn .moreimg{display: none;}
.more-option.show .optionbtn .closeimg{display: block;}
.more-option.show ul{-webkit-transform:scale(1); -moz-transform:scale(1); -o-transform:scale(1); -ms-transform:scale(1); transform:scale(1);}


.groundbg {text-align: center;}
.close-ground{display: none;}
.otherinfo ul{display: flex; align-items: center;}
.otherinfo ul li{margin:0;}
.otherinfo ul li a{width: 90px; height: 35px; background: #FFFFFF; border-radius: 6px; font-size: 12px; line-height: 21px; display: flex; align-content: center; flex-wrap: wrap; padding: 8px 6px; border: 1px solid #ffffff; color: #000000;}
.otherinfo ul li a img{margin-right: 5px;}
.otherinfo ul li a.datebtn{border: 1px solid #DC143C; color: #DC143C;}
.otherinfo ul li a.pointbtn{border: 1px solid #F37921; color: #F37921;}

.switchinfo{display: flex}
.gamepassswitch{ text-align: center; background-color: #4f2a8c; padding: 12px 20px;}
.gamepassswitch strong{display: block; color: #FFFFFF; font-weight: normal; font-size: 14px; padding:0 0 10px; text-transform: uppercase;}
.switch {display: inline-block; position: relative; width: 50px; height: 25px; border-radius: 20px; background: #dfd9ea; transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1); vertical-align: middle; cursor: pointer;}
.switch::before {content: ''; position: absolute; top: 1px; left: 2px; width: 22px; height: 22px; background: #fafafa; border-radius: 50%; transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);}
.switch:active::before {box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(128,128,128,0.1);}
input:checked + .switch {background: #72da67;}
input:checked + .switch::before {left: 27px; background: #FFFFFF;}
input:checked + .switch:active::before {box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(0,150,136,0.2);}


.ground{position: relative;}
.powerbtn{display: flex; align-items: center; justify-content: center; margin: 0 -10px; position: absolute; left: 0; right: 0; bottom: 32px;  flex-wrap: wrap;}
.powerbtn h4{width: 100%; text-align: center; color: #ffffff; font-size: 16px; margin: 0 0px 10px 0px;}
.powerbtn .btn{margin: 0 10px; padding: 5px 28px 5px 3px; border-radius: 31.5px; height: 50px; background-color: #b6b6b6; display: flex; align-items: center; font-weight: 600; font-size: 16px; line-height: 24px; color: #FFFFFF; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s; -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s;}
.powerbtn .btn figure{width: 42px; height: 42px; background: #000000; border-radius: 50%; display: flex; align-items: center; justify-content: center; opacity: 0.58; margin: 0 13px 0 0;}
.triplebtn{background: linear-gradient(100deg, #F86BDF 7.44%, #7F5DD9 61.78%);}
.extrapersonbtn{background: linear-gradient(100deg, #F87A7A 8.24%, #D96AA0 57.92%);}
.dealerbtn{background: linear-gradient(100deg, #FCCF58 10.84%, #FA7E69 67.37%);}
.flipperbtn{background: linear-gradient(100deg, #72FCC3 7.64%, #62D982 64.31%);}

.triplebtn:hover{background: linear-gradient(100deg, #7F5DD9 7.44%, #F86BDF 61.78%);}
.extrapersonbtn:hover{background: linear-gradient(100deg, #D96AA0 8.24%, #F87A7A 57.92%);}
.dealerbtn:hover{background: linear-gradient(100deg, #FA7E69 10.84%, #FCCF58 67.37%);}
.flipperbtn:hover{background: linear-gradient(100deg, #62D982 7.64%, #72FCC3 64.31%);}

.lineup-btn{display: flex; align-items: center; justify-content: center; margin: 0; gap: 10px; background-color: #fff; padding: 10px 12px; border-radius:0 0 12px 12px;}
.lineup-btn .btn{padding:10px 25px;}
.lineup-btn .update-lineup, .lineup-btn .preview-btn{width: 249px; height: 46px; background: #FFD43B; border-radius: 6px; font-size: 18px; line-height: 32px; text-align: center; color: #000000; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s; -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s; margin: 0 5px; font-weight: 600;}
.lineup-btn .update-lineup:hover, .lineup-btn .preview-btn:hover{background: #000000; color: #FFD43B;}
.otherinfo{display: none;}

.powerbtn.powerbtn-box .btn{min-width: inherit; padding-right: 15px;}
.powerbtn.powerbtn-box .btn:after{display: none;}


.sponsor-slider{margin: 50px 0 0;}
.sponsor-slider .slick-track {padding: 20px 0px;}
.sponsor-slider .slick-center{-webkit-transform: scale(1.2); transform: scale(1.2); position: relative; z-index: 1;}
.slick-slide img{opacity: 0.5;}
.sponsor-slider .slick-center img{opacity: 1;}
.sponsor-slider .slick-prev{position: absolute; top: 50%; left: 50%; margin-left: -165px; z-index: 3; width: 50px; height: 50px; border-radius: 50%; background: #FFFFFF url("../images/left-arrow.png") no-repeat center;} 
.sponsor-slider .slick-next{position: absolute; top: 50%; right: 50%; margin-right: -165px; z-index: 3; width: 50px; height: 50px; border-radius: 50%; background: #FFFFFF url("../images/right-arrow.png") no-repeat center;}
.sponsor-slider .slick-prev:before, .sponsor-slider .slick-next:before{display: none;}

.google-ads{margin: 50px 0 50px;}

footer{background:linear-gradient(180deg, #0B1449 0%, #090F35 100%); position: relative;}
footer .container-fluid{position: relative; z-index: 1; padding: 0 30px;}
footer:after{position: absolute; content:''; right: 0; bottom: 0; height: 100%; width: 100%; background-image: url("../images/footerbg.png"); background-repeat: no-repeat; background-position: bottom right;}
.topfooter { padding: 30px 0; display: flex; justify-content: center;}
.topfooter h4{font-size: 20px; line-height: 26px; text-transform: uppercase; margin: 0 0 15px; color: #ffffff;}
.topfooter h4.mobileheading{display:none;}
.topfooter ul li{display: block; padding: 0 0 10px; font-size: 16px; }
.topfooter ul li a{color: #8890BE;}
.topfooter ul li a:hover{color: #ffffff;}
.about-footer{width: 25%; padding-right: 30px;}
.about-footer p { font-size: 16px; line-height: 26px; max-width: 320px; color: #ffffff;}
.link, .about, .get-started, .more{width: 18.75%;}
.social_links {display: flex; align-items: center;}
.social_links li{margin: 0 15px 0 0; font-size: 20px;}
.social_links li a{color: #ffffff;}
.copyyright {margin: 0; text-align: center; padding: 20px 0px; font-size: 16px; line-height: 26px; color: #ffffff; border-top: 1px solid rgba(215,215,215,0.25);}


#nav-menus span{display:block;position:absolute;height:2px;width:100%;background:#ffffff;border-radius:0px;opacity:1;left:0;-webkit-transform:rotate(0deg);-moz-transform:rotate(0deg);-o-transform:rotate(0deg);transform:rotate(0deg); -webkit-transition:.25s ease-in-out;-moz-transition:.25s ease-in-out;-o-transition:.25s ease-in-out;transition:.25s ease-in-out;}
#nav-menus span:nth-child(1){top:0px;}
#nav-menus span:nth-child(2),#nav-menus span:nth-child(3){top:6px;}
#nav-menus span:nth-child(4){top:12px;}
#nav-menus.open span:nth-child(1){top:10px;width:0%;left:50%;}
#nav-menus.open span:nth-child(2){-webkit-transform:rotate(45deg);-moz-transform:rotate(45deg);-o-transform:rotate(45deg);transform:rotate(45deg);}
#nav-menus.open span:nth-child(3){-webkit-transform:rotate(-45deg);-moz-transform:rotate(-45deg);-o-transform:rotate(-45deg);transform:rotate(-45deg);}
#nav-menus.open span:nth-child(4){top:18px;width:0%;left:50%;}
.navbar-toggler{position: absolute; right: 0; top: -5px; border: none; height: 14px; width: 24px; padding: 0; z-index: 10;}
.navbar-toggler:focus{border: none; box-shadow: none;}


.gamemenu-link{position: fixed; left: -300px; top: 0; width: 300px; height: 100%;  background-color: var(--side-menu-background-color);; padding: 40px 15px 15px 15px; z-index: 99; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s; -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s; overflow-y: auto;}
.gamemenu-link.open{left: 0;}
.gamemenu-link .left-section{display: none;}
.gamemenu-link .close{color: #000000; display: block; position: absolute; top: 2px; right: 10px; font-size: 24px;}
.gamemenu-link .close img{max-width: 24px;}
.gamemenu-link ul li{display: block; padding: 10px 0; border-bottom: 1px solid rgba(255,255,255,0.3);}
.gamemenu-link ul li:last-child{border-bottom:none;}
.gamemenu-link ul li a{display: block; color: #ffffff; font-size: 14px;}
.gamemenu-link ul li a:hover{color: #ffffff;}
.gamemenu-overlay{position: fixed; left: 0;  top: 0; height: 100%; width: 100%; background-color: rgba(0,0,0,0.7); opacity: 0; visibility: hidden; z-index: 10;}
.gamemenu-overlay.active{opacity: 1; visibility: visible;}
.mobileads{display: none;}

/*innerwhite section css*/
.middle-section .container{position: relative; z-index: 1;}
.innerwhite-section{padding: 50px 0;}
.innerwhite-section .heading h2{font-size: 40px; line-height: 39px; color: #ffffff; margin: 0 0 25px; text-align: center;}
.innerwhite-section .searchfilter .dropdown{right: 15px; top: 15px;}
.innerwhite-section .searchfilter .dropdown img{max-width: 24px;}

.innerwhite-section .tab-content{background-color: #ffffff; padding: 10px 10px; border-radius: 8px; margin-bottom: 15px;}
.table{margin: 0;}
.table thead tr th{background-color: #0A0F2E; color: #ffffff; font-weight: normal; font-size: 12px; text-align: center; padding: 12px 8px; vertical-align: middle;}
.table tbody tr td{font-size: 14px; padding: 8px 8px; border: none; border-bottom: 6px solid #ffffff; border-top: 6px solid #ffffff;}
.table tbody tr td h5, .table tbody tr th h5{font-weight: 600; font-size: 15px; line-height: 21px; color: #000000; margin: 0; text-align: left;}
.table tbody tr td h5 a, .table tbody tr th h5 a{color: #000000; text-decoration: none;}
.playerinfo .teamname{color: #000000; font-size: 14px; font-weight: normal;}

#addplayer .modal-body .table tr th, #addplayer .modal-body .table tr td{padding: 5px 15px;}
#addplayer .playerinfo{min-height: 40px;}
#addplayer .addplayer img{max-height: 28px;}

.ranktable.table{max-width: 100%; margin: auto;}
.ranktable.table thead tr th, .ranktable.table tbody tr td{padding: 12px 20px;}
/* .ranktable.table thead tr th:nth-child(2){text-align: left;} */
/* .ranktable.table thead tr th:first-child, .ranktable.table tbody tr td:first-child{width: 30px;} */
.ranktable.table tbody tr td{text-align: left;}
.ranktable.table thead tr th{text-align: left;}
.ranktable.table tbody tr td .playerinfo{min-width: 100%; padding: 0px 0px 0px 60px;}
.teamname{text-align:left;}

.rankingtab{border: none; justify-content: center; margin: 0 0 30px;}
.rankingtab .nav-item{margin: 0 10px;}
.rankingtab .nav-item .nav-link{border-radius: 6px;background-color: #ffffff; color: #0A0F2E; border: none; position: relative;}
.rankingtab .nav-item .nav-link.active{border-radius: 6px; background-color: #0A0F2E; color: #ffffff; border: none;}
.rankingtab .nav-item .nav-link.active:after{position: absolute; content: ""; bottom: -10px; right: 0; left: 0; margin: auto; height: 12px; width: 24px; background: url("../images/bottom-arrow-blue.png") no-repeat; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s; -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s;}
.stopscroll{overflow: hidden;}

.playertable.table thead tr th, .playertable.table tbody tr td{text-align: left; padding: 12px 20px;}
.playertable .playername{max-width: 195px; padding-right: 0;}
/*innerwhite section css*/

.stop-scroll{position:fixed; top: 0; left: 0; height: 100%; width: 100%;}
.visible-md{display: none !important;}

.table.legendtable{min-width: 870px;}
.table.full-legend{min-width: 1170px;}
.table.legendtable tr td b{display: block;}

@media (min-width:1200px){
.container{max-width:1200px;}

}
@media (min-width:768px){
.topfooter .collapse:not(.show) {display: block;}
.counter p.completed_message{min-height: 68px; display: flex; align-items: center;}
}
@media (max-width:1660px){
.game-btn li a {font-size: 14px; padding: 8px 15px; height: 44px;}	
.game-state li{padding: 10px;}
.game-state li span {font-size: 12px;}	
.btninfo-block a {font-size: 14px; padding: 9px 10px; display: flex; align-items: center; justify-content: center; flex-wrap: wrap; min-width: 115px;}
.btninfo-block a.lock{min-width: 135px;}	
.btninfo-block a img{margin: 0 5px 0 0; max-width: 20px;}
.btninfo-block a img.lockimg{margin: 0 0 0 5px;}	
.tabledata .nav-tabs .nav-link{font-size: 14px; padding: 10px 8px;}
.powerbtn{margin: 0 -5px;}	
.powerbtn .btn{padding: 5px 15px 5px 3px; font-size: 12px; height: 40px; margin: 0 5px;}	
.powerbtn .btn figure{width: 26px; height: 26px; margin: 0 3px 0 0; padding: 5px;}	
.sponsorlogo{max-width: 420px; top: 20px;}
.game-state {padding: 0 10px;}
.team-fieldview{top: 90px;}	
.team-fieldview > ul{margin: 0 -7px 0;}
.team-fieldview > ul > li {padding: 0 7px;}	
.team-fieldview > figure > img{height: 61px; width: 53px;}
.pitchsection{width: 54%;}	
.groundbg img{height: 800px;}
.left-section > figure {height: 160px; width: 160px; padding: 10px;}
.banner-ads, .brand-logo{height: 180px; width: 180px;}
.more-option{right: 5px;}
}
@media (max-width:1365px){
.btninfo-block {flex-direction: column;}
}
@media (max-width:1199px){
.banner-content {padding:30px 30px; min-height: 180px;}	
.custom-select{font-size: 26px; max-width: 400px;}	
.banner-ads, .brand-logo{height: 160px; width: 160px; margin-left: 15px; margin-right: 0;}
.banner-ads{margin-left: 0;}
.btninfo-block{flex-direction: row; padding: 15px 0;}
.otherinfo{display: block;}
.middle-section{z-index: 1;}
.middle-section.active{z-index: 9;}	
.lineup-btn{margin:0 0 15px 0; width: 100%;}	
.otherinfo .lineup-btn{margin: 0; width: 100%; padding: 0; background: transparent;}	

.groundview{position: fixed; left: 0; bottom: -100%; background: linear-gradient(236.28deg, #CA1744 4.07%, #0B1449 75.27%); z-index: 3; width: 100%; height: 100%; padding: 35px 20px 60px 20px; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s; -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s; overflow-y: auto; z-index: 99999;}
.groundview.open{bottom:0;}
.groundview.open .ground {padding: 0px 20px;}
.close-ground{display: block; position: absolute; top: 5px; right: 5px; z-index: 99;}	
.close-ground img{max-width: 22px;}	
.matchdata{flex-direction: column; padding: 15px 20px 30px;}
.tabledata{width: 100%;}
.pitchsection{width: 100%; padding-top: 0; margin: 0 0 15px;}	
.additional-option{padding-right: 0; justify-content: space-between; margin:0 0 15px; align-items:center;}
.additional-list {padding-left: 20px;}	
.groundbg img{height: 720px;}
.sponsorlogo {top: 15px;}
.team-fieldview{top: 80px; bottom: 190px;}
.team-fieldview > ul{margin: 0 -10px 0;}	
.groundview{padding:0;}	
.powerbtn{bottom: 80px; flex-wrap: wrap;}
.powerbtn.powerbtn-box{max-width: 700px; margin: auto; padding: 0;}
	
.sponsor-slider .slick-prev {margin-left: -132px; width: 40px; height: 40px;}
.sponsor-slider .slick-next {margin-right: -132px; width: 40px; height: 40px;}

.strip-ads.visible-md{justify-content: center; margin: 20px 0 10px;}
	
.hidden-md{display: none !important;}
.visible-md{display: flex !important;}	

.left-block > .row{margin: 0px -5px;}	
.counter ul li {padding: 0 5px;}
.counter ul li strong {width: 32px; height: 28px; font-size: 16px;}
.lineup-btn .update-lineup, .lineup-btn .preview-btn{min-width: 100px; width: auto; font-size: 12px; height: 40px; line-height: 28px;}
.gamebar_detail{padding-left: 20px;}
.game-btn {flex-wrap: wrap; margin-left: 0; margin-top: 0; position: fixed; left: 0; right: 0; bottom: 0; width: 100%; background-color: #000000; z-index: 20; padding: 10px 10px; justify-content: center; display: flex;}
.game-btn li a{padding: 4px 10px; height: 34px; background: #FFD43C; border: 1px solid #FFD43C; border-radius: 6px; color: #000000;}
.game-btn li a:hover{background-color: #ffffff; color: #000000; border: 1px solid #ffffff;}	
.game-btn li a img {margin: 0 5px 0 0; max-width: 15px; filter: -webkit-brightness(0); filter: brightness(0);}
.game-state li:nth-child(5)::after{display: none;}	
.btninfo-block{left: auto; bottom: auto; padding: 0; right: 15px; top: -38px;}
.btninfo-block a {padding: 1px 5px; height: 30px; font-size: 0; min-width: inherit;}
.btninfo-block a.lock{min-width: inherit; font-size: 0;}	
.btninfo-block a img {margin: 0; max-width: 18px;} 

}
@media (max-width:991px){
.navbar-expand-lg .navbar-collapse{display: flex !important; flex-basis: auto;}
.navbar-expand-lg .navbar-toggler{display: none;}	
.header .navbar-expand-lg .navbar-nav{flex-direction: row;}		
	
.left-section > figure{height: 100px; width: 100px;}
.game-state{width:100%;}
.gamebar-rightinfo{flex-wrap:wrap; justify-content: flex-end;}
.gamebar_detail{padding-left: 20px;}
.btninfo-block {position: absolute; left: 30px; bottom: 15px;}	
.custom-select{font-size: 20px;}
.banner-content {padding:15px 30px; min-height: 140px;}
.gamebar_detail p {font-size: 16px; line-height: 22px; padding-left: 20px; margin:5px 0 15px;}

.topfooter h4{font-size: 16px;}
.topfooter ul li{font-size: 13px;}
.about-footer{padding-right: 25px;}	
.about-footer p{font-size: 12px; line-height: 20px;}
.copyyright{font-size: 14px;}

.header .navbar-collapse .dropdown-menu[data-bs-popper] {position: absolute;}
.google-ads{margin: 35px 0 50px;}	
.sponsorlogo{top: 20px; max-width: 470px;}
.groundbg img{height: 850px;}
.team-fieldview{top: 100px; bottom: 220px;}
.leftdata .team-fieldview{bottom: 160px;}
.team-fieldview > ul{margin: 0 -10px 0;}
.close-ground{top: 5px;}


.sponsor-slider .slick-prev {margin-left: -103px; width: 40px; height: 40px;}
.sponsor-slider .slick-next {margin-right: -103px; width: 40px; height: 40px;}

.banner-ads, .brand-logo{height: 80px; width: 80px;}
.custom-select {max-width: 365px;}
.hidden-sm{display: none !important;}	
.header .navbar-expand-lg .nav-item{margin-right: 20px;}
.counter{padding: 0 15px;}
.gamepassswitch{padding: 8px 15px;}	
}
@media (max-width:767px){
body {padding-bottom: 54px;}	
.container{max-width:100%;}	
.header .dropdown-toggle {padding-right: 20px;}
.header .dropdown-toggle::after {background-size: 10px;}	
.header .dropdown-toggle figure{height: 36px; width: 36px;}	
.header .navbar-collapse .dropdown-menu::after{right: 25px;}	
	
.logo img{max-width: 100px;}	
.container-fluid {padding-left: 15px; padding-right: 15px;}
.banner-content{padding: 20px 15px; min-height: inherit;}
.gamebar_detail p{font-size: 12px;}	
.gamebar-rightinfo{flex-direction: column-reverse; width:100%;}	
.game_navbar{flex-direction: column-reverse;}		
.left-section{width: 100%; padding-left: 50px !important; position: relative; min-height: 40px;}	
.left-section > figure{height: 40px; width: 40px; padding: 5px; position: absolute; left: 0; top: 0; border-radius:4px;}
.left-section > figure img{max-width: 30px;}
.game-state {padding: 0;display: flex; justify-content: space-between; background-color: #000000; }
.game-state li{position: relative; display: inline-block; white-space: nowrap; padding: 5px 8px; width: 20%;}
.game-state li:after{position: absolute; content: ""; right: 0; top: 0; bottom: 0; margin: auto; height: 25px; width: 1px; background-color: rgba(255,255,255,0.3);}	
.game-state li:last-child{width: auto;}	
.game-state li:last-child:after{display: none;}	
.game-state li span {font-size: 10px; line-height: 16px; color: #ffffff;}	
.game-state li strong {font-size: 12px; line-height: 20px; color: #ffffff;}		
.gamebar_detail{padding-left: 0; padding-right: 15px;}	
.custom-select {font-size: 15px; max-width: 100%; width: 100%; margin-bottom:0;}

#addplayer .modal-body .table tr th, #addplayer .modal-body .table tr td{padding: 5px 10px;}	
	
.counter p{font-size: 12px; line-height: 12px; margin:10px 0; cursor: pointer; position: relative;}
.counter p:after{position: absolute; content: ""; margin-left: 10px; width: 0; height: 0; top: 4px; right: auto; border-left: 6px solid transparent; border-right: 6px solid transparent; border-top: 6px solid #000000;}	

.counter{padding: 0 10px; width: 100%;}	
.counter ul.showtime{display: flex; padding: 0 0 10px;}	
.counter p{font-size: 12px; line-height: 12px; margin:10px 0; cursor: pointer; position: relative;}
.counter p:after{position: absolute; content: ""; margin-left: 10px; width: 0; height: 0; top: 4px; right: auto; border-left: 6px solid transparent; border-right: 6px solid transparent; border-top: 6px solid #000000;}	
.counter ul{display: table-column; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s; -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s;}	
	
.counter ul li span {font-size: 10px; line-height: 14px;}	
.counter ul li strong {width: 28px; height: 28px; font-size: 14px; line-height: 20px;}
.tabledata .nav-tabs .nav-link{font-size: 10px; padding: 0 5px;}	
.searchfilter .form-control {height: 40px; padding: 6px 6px 5px 35px; font-size: 12px;}
.searchfilter .searchimg {left: 22px; top: 18px;}
.searchfilter .searchimg img{max-width: 15px;}	
.searchfilter{padding: 10px 60px 10px 10px; font-size: 14px; min-height: 42px;}
.searchfilter .dropdown {right: 10px; top: 15px;}
.searchfilter .dropdown img{max-width: 30px;}	
.searchfilter .dropdown .dropdown-menu li a{padding: 10px 15px; font-size: 14px;}	
.infotoggle{display: flex;}	
.infotoggle img{max-width: 15px;}	
.playerinfo{padding: 0px 0px 0px 35px; display: flex; align-items: center;}	
.playerimg {width: 30px; height: 30px; margin: auto;}
.playerimg figure{width: 30px; height: 30px;}
.available::after{width: 8px; height: 8px; top:10px;}
.available{padding: 0px 0 0 12px; font-size: 10px;}
.bonuscard{height: 16px; width: 16px; margin: 2px 0 0 10px;}	
.playername {margin: 0 0 0 0; font-size: 12px; max-width: 100px;}
.playername .teamcircle{width: 16px; height: 16px; font-size: 10px; line-height: 16px; top: 7px; right: 0;}	
.clubname{margin: 0px 0 0 5px; font-size: 10px;}
.tabledata .table tbody tr td:first-child{padding: 0px 5px;}	
.table tr td .title{font-size: 12px;}
.table tr td > strong{font-size: 10px;}
.table tbody tr td .lock-img img{max-width: 20px;}
.addplayer img{max-height: 20px;}	
.matchdata{padding: 10px 10px 10px;}
footer .container-fluid {padding: 0 15px;}
.topfooter {padding: 20px 0 0 0; flex-wrap: wrap;}
.about-footer{padding-right: 0; width: 100%;}
.about-footer p{margin-bottom: 5px;}	
.about-footer figure{margin: 0 0 10px;}
.about-footer figure img{max-height: 28px;}
.link, .about, .get-started, .more{width: 100%; margin-bottom: 15px;}
.topfooter ul{padding: 10px 0 0;}
.topfooter h4{display: none;}
.topfooter h4.mobileheading{display: block; margin: 0; border-bottom: 1px solid rgba(215,215,215,0.25); font-size: 14px; cursor: pointer; background: url("../images/dropdown-arrow.png") no-repeat center right;}
footer::after {background-attachment: fixed;}	
.copyyright{padding:0 0 10px 0px; border: none; font-size: 13px;}

.customscroll{height: 425px; overflow-y: auto;}	

.mobileads img{max-height: 20px;}
.brand-logo{display: none;}
.banner-ads{height: 40px; width: 40px;}
	
.sponsor-slider .slick-prev{margin-left: -87px; width: 30px; height: 30px; background-size:18px;}	
.sponsor-slider .slick-next{margin-right: -87px; width: 30px; height: 30px; background-size:18px;}
	
.innerwhite-section{padding:10px 0 15px 0;}
.innerwhite-section .heading h2{font-size: 30px; margin: 0 0 10px;}

.groundbg{text-align: left;}	
.groundbg img{height: 580px; max-width: 100%; width: 100%;}
.sponsorlogo{top: 10px; max-width: 210px;}
.small-logo {height: 30px; width: 30px;}
.big-logo img{max-height: 18px;}
.team-fieldview{top: 70px; bottom: 165px;}	
.team-fieldview > ul{margin: 0 auto; flex-wrap: wrap; width: 220px;}
.team-fieldview > ul > li{padding: 0 0 10px 0; width: 33.33%; text-align: center;}
.team-fieldview > ul > li:nth-child(4), .team-fieldview > ul li:nth-child(5){margin-top:25px;}
.team-fieldview > figure > img {height: 35px; width: 30px;}	
.otherinfo ul{justify-content: center;}
	
.sponsor-slider{margin: 20px 0 0;}
.google-ads{margin: 15px 0 25px;}
	
.lineup-btn .update-lineup, .lineup-btn .preview-btn {width: auto; min-width: inherit; height: 30px; font-size: 13px; line-height: 15px; padding: 6px 15px;}	
.additional-option {justify-content: center; margin: 0 0 20px; flex-direction: column;}
.additional-list {padding-left: 0; justify-content: center; margin-top: 10px; position: relative;}	
.close-ground {top: 5px; right: 5px;}
.powerbtn{bottom: 45px; flex-wrap: wrap; padding-top: 0; margin: 10px auto; max-width: 300px;}
.powerbtn .btn {margin: 45px 5px; font-size: 10px; height: 32px;}	
.navbar-collapse {top: 50px; height: calc(100% - 80px);}
.additional-list .dropdown-toggle.show::before {bottom: -8px; right: -2px; background-size: 18px;}
.additional-list .dropdown-menu.show {top: 32px !important; padding: 10px 12px; right: -5px !important;}	
.additional-list .dropdown .socialicon ul {padding: 0 0;}	
.additional-list .dropdown .notificationoption li, .additional-list .dropdown .notificationoption li a {font-size: 12px;}
.additional-list .dropdown .keyoption li a{font-size: 12px;}
.additional-list .dropdown .socialicon li a {width: 32px; height: 32px;}
.additional-list .dropdown .tradeoption li a {font-size: 12px; line-height: 38px; height: 40px;}	
	
.header .navbar-collapse .dropdown-menu .dropdown-item{padding: 7px 12px; font-size: 14px;}
	
.rankingtab{margin: 0 0 15px;}	
.rankingtab .nav-item{margin: 0 5px;}
.rankingtab .nav-item .nav-link{padding: 5px 8px; font-size: 12px;}

.ranktable.table thead tr th, .ranktable.table tbody tr td{padding:8px 8px; font-size: 12px; text-align: left;}	
.ranktable .playerinfo{padding: 0px 0px 0px 55px; min-height: 45px; display: flex; align-items: left;}
.ranktable .playername{margin: 0;}
.hidden-xs{display: none !important;}
.close-ground img{max-width: 18px;}
.game-btn li{margin-right:8px;}
.game-btn li a{padding: 4px 7px; height: 34px;}

.matchdata{flex-direction: column;}
.pitchsection{padding-top: 0;}
.additional-option{justify-content: space-between; margin:0; flex-direction: inherit; position: relative; z-index: 3;}
.otherinfo ul li{margin: 0 3px;}
.switch3{width: 65px; height: 28px;}	
.switch3 div::before, .switch3 div::after {width: calc(70px - 30px); height: 27px; line-height: 27px; font-size: 10px; top: -5px; margin-left: 5px;}
.switch3 input:checked + div {left: calc(75px - 30px);}	
.switch3 div {width: 18px; height: 18px;}	
.otherinfo ul li a {width: 70px; height: 30px; font-size: 10px; line-height: 16px; padding: 5px 5px;}	
.otherinfo ul li a img {margin-right: 5px; max-width: 14px;}
.additional-list > li {padding: 0 2px;}
.additional-list {margin: 0;}	
.additional-list li > .dropdown > a.dropdown-toggle {width: 26px; height: 26px; padding: 6px;}
.additional-list .border-btn {padding: 4px 6px; font-size: 12px; line-height: 18px;}
	
.pitchsection{margin: 0 0 15px;}		
.counter ul li{padding: 0 2px;}

.playerimg img.verified {bottom: -3px;}
.ranktable.table tbody tr td .playerinfo{min-width: 100%;}	
.ranktable.table thead tr th:first-child, .ranktable.table tbody tr td:first-child{padding: 8px 0px 8px 8px;}

.gamemenu-link .left-section{display: flex; margin-bottom: 15px; margin-top: 15px;}
.gamemenu-link .close img{max-width: 18px;}
.gamemenu-link{padding: 40px 15px 15px 15px;}
.gamemenu-link .gamebar_detail{padding-right: 0;}

.groundview{padding: 20px 0 0 0;}
.team-fieldview > ul > li > figure > img{height: 40px; width: 34px;}
.addplayerlink{top: 3px; max-width: 30px;}
.addplayerlink img{max-width: 10px;}
.addplayerlink span{font-size: 7px; line-height: 8px; margin-top: -4px;}
.pitchplayer figure img{max-height: 28px;}
.pitchplayer span{font-size: 8px;}
.pitchplayer h5{font-size: 9px; padding:2px 2px 2px 12px; margin: 0 -8px 3px;}	

.switchinfo {align-items: center; justify-content: space-between; width: 100%;}
.gamepassswitch{padding: 3px 10px 5px; width: 100%; align-items: center; justify-content: center; flex-direction: column; display: none;}
.gamepassswitch strong {font-size: 10px; padding: 0px 0px 0px 5px;}
.switch {width: 40px; height: 20px;}	
.switch::before {top: 2px; left: 4px; width: 15px; height: 15px;}
input:checked + .switch::before{left: 20px;}

.game-state li:nth-child(4)::after{display: none;}	
.game-state li:last-child{padding:0;}
.game-state li .gamepassswitch{display: flex;}	
.game-state li .gamepassswitch strong{font-weight: normal; font-size: 10px; padding:0 0 2px; text-transform: uppercase;}
}
@media (max-width:479px){
.right-section{padding: 0;}	
.banner-content{padding: 10px 15px;}	
.gamebar_detail{padding-left: 0;}	
.left-section > figure img{max-width: 30px;}
.gamebar_detail p{line-height: 16px; margin: 0 0 10px;}
.custom-select{font-size: 14px;
    width: 200px; /* Set your desired width */
    display: inline-block; /* Set to inline-block */
    max-width: 200px; /* Set your desired maximum width */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
}

.breakWord{font-size: 14px;
    display: inline-block !important; /* Set to inline-block */
    max-width: 350px !important; /* Set your desired maximum width */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis !important; 
}


#playermoreinfo.modal-dialog{max-width: 670px;}
#playermoreinfo.modal-body{padding: 0;}
.modal-footer{background-color: #0A0F2E; border-bottom: none; color: #ffffff; justify-content: space-between;}
.poweredby{color: #ffffff; font-size: 13px;}
.poweredby img{max-width: 120px; margin-left: 10px;}
#playermoreinfo .modal-header{background-color: #CA1744; border: none; color: #ffffff;}
#playermoreinfo .modal-header .btn-close{opacity: 1; position: absolute; right: -5px; top: -5px; background-color: #ffffff; padding: 5px;}
.modal-footer div{margin: 0;}
.modal-footer .border-btn{height: auto; padding: 10px 14px; font-size: 16px; line-height: 22px;}
.modal-footer .border-btn:hover{border: 1px solid #ffffff;}
.player_star_info{display: flex; align-items: center; justify-content: space-between; background:linear-gradient(236.28deg, #CA1744 4.07%, #0B1449 75.27%); padding: 20px 15px; position: relative;}
.player_star_pic{display: flex; width: 70%; align-items:center;}
.player_profile_url{text-decoration: none; color:#ffffff; position: relative;}
.player_profile_url figure{width:130px; height:130px; background-color:#ffffff;  border-radius:10px; overflow: hidden; display: flex; align-items: center; justify-content: center; flex-wrap: wrap; padding: 5px;}
.player_profile_url figure img{max-height: 130px;}
.player_club_name{color: #ffffff; font-size: 10px; margin: 0; text-align: center; background-color: rgba(0,0,0,0.85); border-radius: 4px; padding: 4px 8px; font-weight: 500; position: absolute; left: 0; right: 0; bottom: 0;}
.player_description{padding: 0 0 0 15px; color: #ffffff;}
.player_description h3{color: #ffffff; font-size: 26px; margin: 0; display: flex; align-items: center;}
.player_description h3 figure {height: 24px; width: 24px; overflow: hidden; background-color: #ffffff; border-radius: 50%; display: flex; align-items: center; justify-content: center; flex-wrap: wrap; padding: 5px; margin-left: 7px;}
.player_description h6{color: rgb(255, 145, 0); font-size: 12px; margin: 0 0 5px;}
.player_description .player_game_name{color: #ffffff; font-size: 14px; margin: 0;} 
.club_logo figure{width:130px; height:130px; border-radius:10px; overflow: hidden; background-color: #ffffff; display: flex; align-items: center; justify-content: center; flex-wrap: wrap; padding: 10px;}
.view-profile a{color: #FFD43C; font-size: 14px; text-decoration: underline; font-weight: 500;}

.game_progress_info{padding: 20px 15px 0; display: flex;  justify-content: center; flex-wrap: wrap;}
.game_progress_info li{padding: 0 5px 10px; width:33.33%;}
.progress_info{padding: 15px 10px; text-align: center; background-color: #f4f3f6; height: 100%; font-size: 16px; border-radius: 6px;}
.progress_info strong{display: block; font-size: 16px;}

.playertabinfo{padding: 20px 15px; display: none;}
.playertabinfo{border: none; margin:0;}
.playertabinfo .nav-tabs{border-bottom: none; margin-bottom: 15px;}
.playertabinfo .nav-item{margin: 0; width: 50%;}
.playertabinfo .nav-item .nav-link{border-radius: 0; background-color: #eaeaea; color: #0A0F2E; border: none; position: relative; width: 100%;}
.playertabinfo .nav-item .nav-link.active{border-radius: 0; background-color: #0A0F2E; color: #ffffff; border: none;}
.playertabinfo .nav-item .nav-link.active:after{position: absolute; content: ""; bottom: -10px; right: 0; left: 0; margin: auto; height: 12px; width: 24px; background: url("../images/bottom-arrow-blue.png") no-repeat; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s; -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s;}
.stopscroll{overflow: hidden;}
.playertabinfo .table{margin: 0;}

.player-performance{padding: 10px 20px;}
.player-performance h5{background-color: #0A0F2E;text-align: left;color: #ffffff;padding: 14px 15px;border-radius: 6px; margin: auto;}
.player-performance ul{display: flex; flex-wrap: wrap; align-content: center; margin: 0 -10px;}
.player-performance ul li{font-weight: 500; font-size: 16px; line-height: 22px; padding: 5px 10px; position: relative; width: 50%;}
.performancebox{border-bottom: 1px solid #ddd;display: flex;align-items: center;justify-content: space-between; padding: 8px 0;}
.player-performance ul li:nth-last-child(2) .performancebox, .player-performance ul li:last-child .performancebox{border-bottom: none;}
.player-performance strong{font-weight: 700; color: #0A0F2E;}

.player-performance1{padding: 10px 20px;}
.player-performance1 h5{background-color: #0A0F2E;text-align: left;color: #ffffff;padding: 14px 15px;border-radius: 6px; margin: auto;}
.player-performance1 ul{display: flex; flex-wrap: wrap; align-content: center; margin: 0 -10px;}
.player-performance1 ul li{font-weight: 500; font-size: 16px; line-height: 22px; padding: 0px 10px; position: relative; width: 100%;}
.performancebox1{border-bottom: 1px solid #ddd;display: flex;align-items: center;justify-content: space-between; padding: 8px 0;}
.round-lineup-btn{display: flex; align-items: center; justify-content: center; margin: 0; gap: 10px; padding: 10px 12px; border-radius:0 0 12px 12px;}
/* .round-lineup-btn .btn{padding:10px 25px;} */
.round-lineup-btn .update-lineup, .round-lineup-btn .team-preview-btn{
  width: 80px; height: 40px; 
  background: #FFD43B; border-radius: 6px; font-size: 16px; text-align: center; color: #000000; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s; -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s; margin: 0 5px; font-weight: 600;}

@media (max-width:767px){
header{z-index: 12;}
.stopscroll header{z-index: 9;}
.innerwhite-section .tab-content{padding: 10px 5px;}	
.playerinfo .teamname{font-size: 12px;}	
.teamname{white-space: nowrap; text-overflow: ellipsis; overflow: hidden; max-width: 135px;}  
#playermoreinfo .modal-dialog{max-width: 100%; margin: 16px 8px 8px 8px;}
#playermoreinfo .modal-header{padding: 10px 10px;}	
#playermoreinfo .modal-header .poweredby{font-size: 10px;}	
#playermoreinfo .modal-header .poweredby img{max-width: 90px;}
#playermoreinfo .modal-header .btn-close {right: 5px; padding: 3px; height: 16px; width: 16px;}	
#playermoreinfo .table thead tr th, #playermoreinfo .table tbody tr td{font-size: 12px; padding: 8px 8px;}
#playermoreinfo .modal-footer .border-btn {padding:5px 6px; font-size: 12px;}
#playermoreinfo .poweredby img{max-width: 100px;}	
.view-profile a{font-size: 12px;}	
.modal-title{font-size: 14px;}
.playertabinfo{padding: 10px 10px;}	
.club_logo figure{width: 60px; height: 60px; padding: 5px;}
.player_profile_url figure{width: 60px; height: 60px;}
.player_profile_url figure img{max-height: 60px;}	
.player_star_info{padding: 10px 10px;}	
.player_star_pic{width: 80%;}
.player_club_name{font-size: 8px; padding: 2px 4px;}
.player_description h3{font-size:18px;}
.player_description h3 figure{height: 20px; width: 20px; padding: 4px;}	
.player_description h6{font-size:10px;}
.player_description .player_game_name{font-size:12px;}
.game_progress_info ul li {font-size: 12px; margin: 10px 0px;}
.playertabinfo .nav-item .nav-link {font-size: 14px;}	
.game_progress_info{padding: 15px 10px 0;}
.game_progress_info li{padding: 0 2px 5px;}	
.progress_info{padding: 10px 5px; font-size: 12px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.player-performance{padding: 0px 10px 10px 10px; border-top: 1px solid #ddd; margin-top: 10px;}
.player-performance ul li{font-size: 12px; padding: 0px 10px;}	
.player-performance h5 {padding: 10px 10px; font-size: 14px;}
.innerwhite-section .searchfilter .dropdown{right: 10px; top: 9px;}
.pitchplayer h5 .available::after, .pitchplayer h5 .unavailable::after{top: 2px;}

.powerbtn.powerbtn-box{margin: 10px auto;  padding: 0 15px;}	
.powerbtn h4{font-size: 14px;}
.leftdata .team-fieldview{bottom: 120px;}

.team-fieldview > ul > li > figure{height: 45px; width: 50px;}
.header .navbar-expand-lg .nav-item .gameimg img{max-width: 22px;}
.modal-footer{padding: 10px 10px;}
.poweredby img{max-width: 80px;}
.modal-footer .border-btn {padding: 6px 15px; font-size: 14px;}	

.header .dropdown-toggle, .header .dropdown .dropdown-toggle{padding-left: 0;}
.breakWord { max-width: 204px !important;}

.matchdata .tabledata .tab-content .customscroll{max-height: 660px;}
.game-state li strong {max-width: 150px; overflow: hidden; text-overflow: ellipsis;}

.lineup-btn .btn{padding: 6px 15px; font-size: 14px;}

.playername{white-space: nowrap; text-overflow: ellipsis; overflow: hidden; max-width: 135px;}

.round-lineup-btn{display: flex; align-items: center; justify-content: center; margin: 0; gap: 10px; padding: 10px 12px; border-radius:0 0 12px 12px;}
/* .round-lineup-btn .btn{padding:10px 25px;} */
.round-lineup-btn .update-lineup, .round-lineup-btn .team-preview-btn{
  /* width: 200px; height: 46px;  */
  background: #FFD43B; border-radius: 6px; font-size: 12px; text-align: center; color: #000000; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s; -o-transition: all 0.5s ease 0s; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s; margin: 0 5px; font-weight: 600;}
}
@media (max-width: 374px){
.game-btn{padding: 10px 5px; justify-content: space-around;}
.game-btn li{margin-right: 0;}
.game-btn li a{padding: 4px 5px; font-size: 13px;}
}


.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #a6a8b5;
}

.divider:not(:empty)::before {
  margin-right: .25em;
}

.divider:not(:empty)::after {
  margin-left: .25em;
}
